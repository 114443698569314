import roof_sheet_01 from './assets/Product/Roofing_Sheets/Roof_sheet/green_roof.png'
import roof_sheet_02 from './assets/Product/Roofing_Sheets/Roof_sheet/brown_roof.png'
import roof_sheet_03 from './assets/Product/Roofing_Sheets/Roof_sheet/blue_roof.png'
import roof_sheet_04 from './assets/Product/Roofing_Sheets/Roof_sheet/darkbrown_roof.png'

import tile_roof_sheet_01 from './assets/Product/Roofing_Sheets/Tile_roof_sheet/brown_tile__roof.png'
import tile_roof_sheet_02 from './assets/Product/Roofing_Sheets/Tile_roof_sheet/blue_tile__roof.png'
import tile_roof_sheet_03 from './assets/Product/Roofing_Sheets/Tile_roof_sheet/green_tile__roof.png'
import tile_roof_sheet_04 from './assets/Product/Roofing_Sheets/Tile_roof_sheet/darkbrown_tile__roof..jpg'
import tile_roof_sheet_05 from './assets/Product/Roofing_Sheets/Tile_roof_sheet/red_tile__roof.png'

import glade_roof_sheet_01 from './assets/Product/Roofing_Sheets/gate_gladding_sheet/red_roof.png'
import glade_roof_sheet_02 from './assets/Product/Roofing_Sheets/gate_gladding_sheet/blue_roof.png'
import glade_roof_sheet_03 from './assets/Product/Roofing_Sheets/gate_gladding_sheet/gold_roof.png'
import glade_roof_sheet_04 from './assets/Product/Roofing_Sheets/gate_gladding_sheet/green_roof.png'

import curve_roof_sheet_01 from './assets/Product/Roofing_Sheets/curve_sheet/red_roof.png'
import curve_roof_sheet_02 from './assets/Product/Roofing_Sheets/curve_sheet/green_roof.png'

import arch from './assets/Product/Roofing_Sheets/Arch_Sheet/brown_arch_sheet.PNG'

import Down_pipe_01 from './assets/Product/Accessories/Down_Pipe/Red_Down_Pipe.png'
import Down_pipe_02 from './assets/Product/Accessories/Down_Pipe/Blue_Down_Pipe.png'
import Down_pipe_03 from './assets/Product/Accessories/Down_Pipe/Brown_Down_Pipe.png'
import Down_pipe_04 from './assets/Product/Accessories/Down_Pipe/Green_Down_Pipe.png'

import Gutters_01 from './assets/Product/Accessories/Gutters/brown_gutters.jpg'
import Gutters_02 from './assets/Product/Accessories/Gutters/black_gutters.jpg'
import Gutters_03 from './assets/Product/Accessories/Gutters/green_gutters.jpg'
import Gutters_04 from './assets/Product/Accessories/Gutters/blue_gutters.jpg'

import auto_rollo_door_01 from './assets/Product/Rollor_Doors/auto rollor door/auto_rollor_door01.JPG'
import auto_rollo_door_02 from './assets/Product/Rollor_Doors/auto rollor door/auto_rollor_door02.JPG'
import auto_rollo_door_03 from './assets/Product/Rollor_Doors/auto rollor door/auto_rollor_door03.JPG'

import manual_rollo_door_01 from './assets/Product/Rollor_Doors/manual rollor door/manual_rollor_door01.jpg'
import manual_rollo_door_02 from './assets/Product/Rollor_Doors/manual rollor door/manual_rollor_door02.jpg'

import ridge_01 from './assets/Product/Accessories/Ridge Cap/brown_ridge.png'
import ridge_02 from './assets/Product/Accessories/Ridge Cap/blue_ridge.png'
import ridge_03 from './assets/Product/Accessories/Ridge Cap/black_ridge.png'

import vally_gutter_01 from './assets/Product/Accessories/Vally Gutters/Brown Vally Gutter.png'
import vally_gutter_02 from './assets/Product/Accessories/Vally Gutters/Black Vally Gutter.png'
import vally_gutter_03 from './assets/Product/Accessories/Vally Gutters/Blue Vally Gutter.png'

import vadimbu_01 from './assets/Product/Accessories/Vadimbu/Brown_Vadimbu.jpg'
import vadimbu_02 from './assets/Product/Accessories/Vadimbu/Green_Vadimbu.jpg'
import vadimbu_03 from './assets/Product/Accessories/Vadimbu/Blue_Vadimbu.jpg'
import vadimbu_04 from './assets/Product/Accessories/Vadimbu/Balck_Vadimbu.jpg'

import gutter_falshing_01 from './assets/Product/Accessories/Gutter Flashing/Brown_gutter_flashing.jpg'
import gutter_falshing_02 from './assets/Product/Accessories/Gutter Flashing/Green_gutter_flashing.jpg'
import gutter_falshing_03 from './assets/Product/Accessories/Gutter Flashing/Blue_gutter_flashing.jpg'
import gutter_falshing_04 from './assets/Product/Accessories/Gutter Flashing/Black_gutter_flashing.jpg'

import nozzel_01 from './assets/Product/Accessories/Nozzel/Nozzel_Brown.jpg'
import nozzel_02 from './assets/Product/Accessories/Nozzel/Nozzel_Blue.jpg'
import nozzel_03 from './assets/Product/Accessories/Nozzel/Nozzel_Green.jpg'
import nozzel_04 from './assets/Product/Accessories/Nozzel/Nozzel_Black.jpg'

import endcap_01 from './assets/Product/Accessories/End Cap/endcap_brown.jpg'
import endcap_02 from './assets/Product/Accessories/End Cap/endcap_blue.jpg'
import endcap_03 from './assets/Product/Accessories/End Cap/endcap_green.jpg'
import endcap_04 from './assets/Product/Accessories/End Cap/endcap_black.jpg'

import strirrup01 from './assets/Product/Steel/stirrup/IMG_8049.jpg'
import strirrup02 from './assets/Product/Steel/stirrup/IMG_8050.jpg'
import strirrup03 from './assets/Product/Steel/stirrup/IMG_8051.jpg'

import cpurlin01 from './assets/Product/Steel/c-purlin/IMG_8046.jpg'
import cpurlin02 from './assets/Product/Steel/c-purlin/IMG_8048.jpg'

export const roofing_sheets = {
    tile_roof_sheet : {
        tittle:'Tile Roof Sheet',
        images:[
            {
                colorCode : '#AA4F43',
                img : tile_roof_sheet_05,
                name : 'Autmn Red' 
            },
            {
                colorCode : '#2D5AB4',
                img : tile_roof_sheet_02,
                name : 'Blue' 
            },
            {
                colorCode : '#4F1D19',
                img : tile_roof_sheet_01,
                name : 'Maroon' 
            },
            {
                colorCode : '#54897C',
                img :tile_roof_sheet_03,
                name : 'Dark green' 
            },
            {
                colorCode : '#786669',
                img :tile_roof_sheet_04,
                name : 'Coffee brown' 
            },
        ],
        description:"Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. We provide PPGL sheet with a unique tile roof design  which has a Stunning Layout  and more attractive and demanding among customers",
        benifits:['Premium Quality' , 'Attractive among customers' , 'Provide with proper gauges' , 'The length of the sheet can be provided according to the customer prefrences' , 'Easy installation' ],
        thikness:['0.40 MM' , "0.47 MM"]
    },
    roof_sheet : {
        tittle:'Normal Roof Sheet',
        images:[
            {
                colorCode : '#AA4F43',
                img : roof_sheet_02,
                name : 'Autmn Red' , 
            },
            {
                colorCode : '#3F78E9',
                img : roof_sheet_03,
                name : 'Blue' 
            },
            {
                colorCode : '#786669',
                img : roof_sheet_04,
                name : "Coffee brown" 
            },
            {
                colorCode : '#54897C',
                img : roof_sheet_01,
                name : 'Dark green ',
            },
        ],
        description:'Pre-Painted Galvanized sheet are coated with variety of Colour , This assures the colour and shine for a long period. which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present.2.	We provide PPGL sheet with a unique roof sheet design which has fantastically circulate among customers for industry and residential purposes',
        benifits:['Premium Quality' , 'Provide with proper gauges' , 'The length of the sheet can be provided according to the customer prefrences' , 'Easy installation'],
        thikness:['0.18 MM', '0.20 MM' , '0.25 MM', "0.28 MM", '0.35 MM' , '0.40 MM' , '0.47 MM']
    },
    gate_galldin_sheet : {
        tittle:'Gate Cladding Sheet',
        images:[
            {
                colorCode : '#EF8A84',
                img : glade_roof_sheet_01,
                name : 'Autmn Red', 
            },
            {
                colorCode : '#3F78E9',
                img : glade_roof_sheet_02,
                name : 'Blue'
            },
            {
                colorCode : '#996F3F',
                img : glade_roof_sheet_03,
                name : 'Maroon'
            },
            {
                colorCode : '#54897C',
                img : glade_roof_sheet_04,
                name : 'Dark green',
            },
        ],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. We provide PPGL sheet with a unique gate cladding sheet design which is mainly used for Gate and Cladding purposes',
        benifits:['Premium Quality ', "Provide with proper gauges , The length of the sheet can be provided according to the customer prefrences" , 'Easy installation'   ],
        thikness:['0.25 MM', '0.28 MM', '0.35 MM' , '0.40 MM' , '0.47 MM']
    },
    curve_roof_sheet : {
        tittle:'Curve Roof Sheet',
        images:[
            {
                colorCode : '#BC5D57',
                img : curve_roof_sheet_01,
                name : 'Autmn Red'
            },
            {
                colorCode : '#4E7979',
                img :curve_roof_sheet_02,
                name : 'Dark green'
            }
        ],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. We Povide Roof sheet with curved  design which is mainly used for outdoor and residencial purposes',
        benifits:['Premium Quality' , 'Sheet can be curved according to customer preferences' , 'Provide with proper gauges' , 'The length of the sheet can be provided according to the customer prefrences' , "Easy installation"   ],
        thikness:["0.28 MM", '0.35 MM' , '0.40 MM' , '0.47 MM' ]
    },
    arch_roof_sheet : {
        tittle:'Arch Roof Sheet',
        images:[
            {
                colorCode : '#A23816',
                img : arch,
                name : 'Autmn Red'
            },
        ],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. We Povide Roof sheet with Arch design which is mainly used for outdoor, indoor and residence purposes',
        benifits:['Premium Quality' , 'Sheet can be curved according to customer preferences' , 'Provide with proper gauges' , 'The length of the sheet can be provided according to the customer prefrences' , 'Easy installation',   ],
        thikness:["0.28 MM", '0.35 MM' , '0.40 MM' , '0.47 MM']
    }
}

export const Accessories = {
    Down_Pipe : {
        tittle:'Down Pipe',
        images:[Down_pipe_01 , Down_pipe_02 , Down_pipe_03 , Down_pipe_04],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. Accessories are used for the completing functions of roof or any other commercial activities which gives a beautiful look',
        benifits:['Premium Quality' , 'Provide with proper gauges' , 'The length of the Accessories can be provided according to the customer prefrences'  , 'Easy installation'],
        thikness:['f', '0.47 MM'],
    },
    gutters : {
        tittle:'Gutters',
        images:[Gutters_01 , Gutters_02 , Gutters_03 , Gutters_04],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. Accessories are used for the completing functions of roof or any other commercial activities which gives a beautiful look',
        benifits:['Premium Quality' , 'Provide with proper gauges' , 'The length of the Accessories can be provided according to the customer prefrences'  , 'Easy installation'],
        thikness:['f', '0.47 MM'],
    },
    RidgeCup : {
        tittle:'Ridge Cup',
        images:[ridge_01 , ridge_02 , ridge_03],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. Accessories are used for the completing functions of roof or any other commercial activities which gives a beautiful look',
        benifits:['Premium Quality' , 'Provide with proper gauges' , 'The length of the Accessories can be provided according to the customer prefrences'  , 'Easy installation'],
        thikness:['f', '0.47 MM'],
    },
    VallyGutters : {
        tittle:'Valley Gutters',
        images:[vally_gutter_01 , vally_gutter_02 , vally_gutter_03],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. Accessories are used for the completing functions of roof or any other commercial activities which gives a beautiful look',
        benifits:['Premium Quality' , 'Provide with proper gauges' , 'The length of the Accessories can be provided according to the customer prefrences'  , 'Easy installation'],
        thikness:['f', '0.47 MM'],
    },
    Vadimbu : {
        tittle:'Vadimbu',
        images:[vadimbu_01 , vadimbu_02 , vadimbu_03 , vadimbu_04],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. Accessories are used for the completing functions of roof or any other commercial activities which gives a beautiful look',
        benifits:['Premium Quality' , 'Provide with proper gauges' , 'The length of the Accessories can be provided according to the customer prefrences'  , 'Easy installation'],
        thikness:['f', '0.47 MM'],
    },
    GutterFalshing : {
        tittle:'Gutter Flashing',
        images:[gutter_falshing_01 , gutter_falshing_02 , gutter_falshing_03 , gutter_falshing_04 ],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. Accessories are used for the completing functions of roof or any other commercial activities which gives a beautiful look',
        benifits:['Premium Quality' , 'Provide with proper gauges' , 'The length of the Accessories can be provided according to the customer prefrences'  , 'Easy installation'],
        thikness:['f', '0.47 MM'],
    },
    Nozzel : {
        tittle:'Nozzel',
        images:[nozzel_01 , nozzel_02 , nozzel_03 , nozzel_04 ],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. Accessories are used for the completing functions of roof or any other commercial activities which gives a beautiful look',
        benifits:['Premium Quality' , 'Provide with proper gauges' , 'The length of the Accessories can be provided according to the customer prefrences'  , 'Easy installation'],
        thikness:['f', '0.47 MM'],
    },
    EndCap : {
        tittle:'End Cap',
        images:[endcap_01 , endcap_02 , endcap_03 ,endcap_04 ],
        description:'Pre-Painted Galvanized sheet are coated with variety of colours,This assures the colour and shine for a long period.which is spreading rapidly all over the world and it has turn out to be one of the best constructing material at present. Accessories are used for the completing functions of roof or any other commercial activities which gives a beautiful look',
        benifits:['Premium Quality' , 'Provide with proper gauges' , 'The length of the Accessories can be provided according to the customer prefrences'  , 'Easy installation'],
        thikness:['f', '0.47 MM'],
    },
}

export const Rollor_Door = {
    Auto_Rollor_Door : {
        tittle:'Auto Rollor Door',
        images:[auto_rollo_door_01, auto_rollo_door_02 , auto_rollo_door_03],
        description:'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum',
        benifits:['Warrenty will provide 5 yaer' , 'Reduced usage of wood' , 'Primum quality raw metrial' , 'Durable and fire' , 'Warrenty will provide 5 yaers'],
        thikness:['12.5 mm' , '15.5 mm' , '17.5 mm']
    },
    Manual_Rollor_Door : {
        tittle:'Manual Rollor Door',
        images:[manual_rollo_door_01 ,manual_rollo_door_02],
        description:'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum',
        benifits:['Warrenty will provide 5 yaer' , 'Reduced usage of wood' , 'Primum quality raw metrial' , 'Durable and fire' , 'Warrenty will provide 5 yaers'],
        thikness:['12.5 mm' , '15.5 mm' , '17.5 mm']
    },
}

export const Steel = {
    stirrup: {
        tittle:'Stirrup',
        images:[strirrup01 , strirrup02 , strirrup03],
        description:'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum',
        benifits:['Warrenty will provide 5 yaer' , 'Reduced usage of wood' , 'Primum quality raw metrial' , 'Durable and fire' , 'Warrenty will provide 5 yaers'],
        thikness:['12.5 mm' , '15.5 mm' , '17.5 mm']
    },
    cpurlin: {
        tittle:'C-purlin',
        images:[cpurlin01 , cpurlin02],
        description:'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum',
        benifits:['Warrenty will provide 5 yaer' , 'Reduced usage of wood' , 'Primum quality raw metrial' , 'Durable and fire' , 'Warrenty will provide 5 yaers'],
        thikness:['12.5 mm' , '15.5 mm' , '17.5 mm']
    }
}

