import React from 'react'
import './About.css'
import {MdRoofing} from 'react-icons/md'
import {AiFillCheckCircle} from 'react-icons/ai'
import {IoMdCall} from 'react-icons/io'
import aboutContImge from '../../assets/images/about-one-shape.png'
import aboutimg01 from '../../assets/images/about_img01.jpg'
import aboutimg02 from '../../assets/images/about_img02.jpg'

function About() {
  return (
    <div className='Conatiner about'  id='About'>
        <img className='about-tranotion-image' src={aboutContImge} alt='aboutContImge'/>
        <div className='inner-container inner-container-about'>
            <div className='des-content'>

                <p  data-aos="fade-up" data-aos-duration="800" className='des-tiitle'><MdRoofing />About Our Company</p>
                <h2  data-aos="fade-up" data-aos-duration="800" >Experienced & quality roofing services providers </h2>
                <p  data-aos="fade-up" data-aos-duration="800" className='des-para'>Nulla commodo dolor massa, vel pellentesque nulla congue quis. Fusce ut convallis diam. Nam id tortor et nunc tempor faucibus. Sed eu leo egestas, imperdiet felis sed, vestibulum ligula. </p>

                <div className='vission-mission'>
                    <div  data-aos="fade-right" data-aos-duration="800" className='vission'>
                        <div className='vm-head' >
                             <AiFillCheckCircle />
                            <h3>Vission</h3>
                        </div>
                        <p>Provide uninterrupted services to optimize customer satisfaction and to become leading manufacturers and distributors in sri lanka</p>
                    </div>
                    <div  data-aos="fade-left"  data-aos-duration="800" className='mission' >
                        <div className='vm-head' >
                            <AiFillCheckCircle />
                            <h3>Mission</h3>
                        </div>
                        <p>Supply the products efficiently and effectively with supreme quality and fulfill the customer requirements to the fullest</p>
                    </div>
                </div>

                <button  data-aos="fade-up" data-aos-duration="800" className='button about-btn'><a href="https://wa.me/+94763607727"  target="_blank" rel="noreferrer"><IoMdCall /><span>Contact Us</span></a></button>

            </div>
            <div className='image-cont'>
                <div className='image-container' >
                    <img  data-aos="fade-right" data-aos-duration="800" className='aboutimg01' src={aboutimg01} alt='aboutimg01' />
                    <img  data-aos="fade-left" data-aos-duration="800" className='aboutimg02' src={aboutimg02} alt='aboutimg02' />
                </div>
            </div>
        </div>
    </div>
  )
}

export default About