import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import HomePage from "./pages/HomePage/HomePage";
import ContactPage from "./pages/ContactPage/ContactPage";
import { Routes, Route } from "react-router-dom";
import PreLoader from "./components/PreLoader/Preloader";
import { useCikContext } from "./Context/CentralIndustrisContaxtProvider";
import AboutPage from "./pages/AboutPage/AboutPage";

function App() {
  const {preloaderstatus , setPreloaderStatus} = useCikContext()

  useEffect(() => {
    Aos.init({});
  }, []);

  useEffect(() => {
      setTimeout(() => {
        setPreloaderStatus(false)
      },4000)
      console.log(preloaderstatus )
  },[preloaderstatus , setPreloaderStatus ])

  return (
    <>
      {
        false ? (<PreLoader />) 
        :(
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/Contact" element={<ContactPage />} />
              <Route path="/About" element={<AboutPage />} />
            </Routes>
        )
      }
    </>
  );
}

export default App;
