import React, { useEffect } from "react";
import "./Prooducts.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandshakeAngle } from "@fortawesome/free-solid-svg-icons";
import logo from "../../assets/logoblack.png";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { IoMdCall } from "react-icons/io";

import {
  roofing_sheets,
  Accessories,
  Rollor_Door,
  Steel,
} from "../../product-db";
import { useCikContext } from "../../Context/CentralIndustrisContaxtProvider";

function Prooducts() {
  const { mainprodcat, setMainProdCat, prod } = useCikContext();

  const setMainProdFunc = (cat) => {
    console.log(mainprodcat);
    setMainProdCat(cat);
  };

  return (
    <div className="Conatiner our-services-conatiner" id="Products">
      <div className="our-services-innnercontainer" data-aos="fade-up">
        <div className="our-services-head">
          <p data-aos="fade-up" className="ser-hp">
            <FontAwesomeIcon icon={faHandshakeAngle} />
            <span>Our Products</span>
          </p>
          <h3 data-aos="fade-up">WHAT WE HAVE</h3>
          <p data-aos="fade-up" className="ser-pp">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium totam rem aperiam, eaque ipsa quae
            ab illo inventore veritatis et quasi.{" "}
          </p>
        </div>

        <div className="Main-produt-button-container">
          <button
            onClick={() => setMainProdFunc("Roofing")}
            className={`button-main-prod  ${
              mainprodcat === "Roofing" && "active"
            }`}
          >
            <span>Roofing</span>
          </button>
          <button
            onClick={() => setMainProdFunc("Accessories")}
            className={`button-main-prod  ${
              mainprodcat === "Accessories" && "active"
            }`}
          >
            <span>Accessories</span>
          </button>
          <button
            onClick={() => setMainProdFunc("Rollor Gates")}
            className={`button-main-prod  ${
              mainprodcat === "Rollor Gates" && "active"
            }`}
          >
            <span>Rollor Gates</span>
          </button>
          <button
            onClick={() => setMainProdFunc("Steel")}
            className={`button-main-prod  ${
              mainprodcat === "Steel" && "active"
            }`}
          >
            <span>Steel</span>
          </button>
        </div>

        {mainprodcat === "Roofing" && prod.images && <RoofingProduct />}
        {mainprodcat === "Accessories" && prod.images && <AccessoriesProduct />}
        {mainprodcat === "Rollor Gates" && prod.images && <RollorDoorProduct />}
        {mainprodcat === "Steel" && prod.images && <SteelProduct />}
      </div>
    </div>
  );
}

const RoofingProduct = () => {
  const {
    prod,
    setProd,
    imageIndex,
    setImageIndex,
    setEachImageStatus,
    setEachImage,
  } = useCikContext();

  useEffect(() => {
    setProd(roofing_sheets.roof_sheet);
    console.log(roofing_sheets.roof_sheet)
  }, [setProd]);

  const setRoofSheet = () => {
    setImageIndex(0);
    setProd(roofing_sheets.roof_sheet);
  };

  const setTileRoofSheet = () => {
    setImageIndex(0);
    setProd(roofing_sheets.tile_roof_sheet);
  };

  const gateGladdingSheet = () => {
    setImageIndex(0);
    setProd(roofing_sheets.gate_galldin_sheet);
  };

  const curveRoofSheet = () => {
    setImageIndex(0);
    setProd(roofing_sheets.curve_roof_sheet);
  };

  const archRoofSheet = () => {
    setImageIndex(0);
    setProd(roofing_sheets.arch_roof_sheet);
  };

  const setImage = (img) => {
    setEachImageStatus(true);
    setEachImage(img);
  };

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="800"
      className="roofing-main-container"
    >
      <h2>Roofing Sheets</h2>
      <div className="options-conatiner">
        <div
          className={`options ${
            prod?.tittle === "Normal Roof Sheet" && "active"
          } `}
          onClick={setRoofSheet}
        >
          <h3 className="option-h3">Normal</h3>
          <h3>Roof Sheet</h3>
        </div>
        <div
          className={`options ${
            prod?.tittle === "Tile Roof Sheet" && "active"
          } `}
          onClick={setTileRoofSheet}
        >
          <h3 className="option-h3">Tile</h3>
          <h3>Roof Sheet</h3>
        </div>
        <div
          className={`options ${
            prod?.tittle === "Gate Cladding Sheet" && "active"
          } `}
          onClick={gateGladdingSheet}
        >
          <h3 className="option-h3">Gate</h3>
          <h3>Cladd Sheet</h3>
        </div>
        <div
          className={`options ${
            prod?.tittle === "Curve Roof Sheet" && "active"
          } `}
          onClick={curveRoofSheet}
        >
          <h3 className="option-h3">Curve</h3>
          <h3>Sheet</h3>
        </div>
        <div
          className={`options ${
            prod?.tittle === "Arch Roof Sheet" && "active"
          } `}
          onClick={archRoofSheet}
        >
          <h3 className="option-h3">Arch</h3>
          <h3>Sheet</h3>
        </div>
      </div>

      <div className="option-description">
        <div className="op-inner-container">
          <div className="op-des-img">
            <div className="op-des-img-container">
              <div style={{background : prod?.images[imageIndex].colorCode}} className="color-code" >{prod?.images[imageIndex].name}</div>  
              <img
                onClick={() => setImage(prod?.images[imageIndex].img)}
                src={prod?.images[imageIndex].img}
                alt="service01"
                className="product-img"
              />
              <img src={logo} alt={logo} className="product-logo" />
            </div>

            <div className="options-image-select">
              {prod.images.map((item, index) => {
                return (
                  <img
                    key={index}
                    src={item.img}
                    onClick={() => setImageIndex(index)}
                    alt={logo}
                  />
                );
              })}
            </div>
          </div>
          <div className="op-des-cont">
            <h2>{prod?.tittle}</h2>
            <div className="op-des">
              <h3>Description</h3>
              <p>{prod?.description}</p>
            </div>
            <div className="op-des benifits">
              <h3>Benifits</h3>
              {prod?.benifits.map((benifit, index) => {
                return (
                  <p key={index}>
                    <BsArrowRightCircleFill />
                    <span>{benifit}</span>
                  </p>
                );
              })}
            </div>
            <div className="op-des benifits">
              <h3>Awailable Thikness - Size</h3>
              <div className="thik">
                {prod?.thikness.map((thick) => (
                  <div>{thick}</div>
                ))}
              </div>
            </div>
            <button className="button about-btn product-btn">
              <a
                href="https://wa.me/+94763607727?text= Is this product awailable now ?"
                target="_blank"
                rel="noreferrer"
              >
                <IoMdCall />
                <span>Contact Now</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccessoriesProduct = () => {
  const {
    prod,
    setProd,
    imageIndex,
    setImageIndex,
    setEachImageStatus,
    setEachImage,
  } = useCikContext();

  useEffect(() => {
    setImageIndex(0);
    setProd(Accessories.gutters);
  }, [setProd, setImageIndex]);

  const setDownPipe = () => {
    setImageIndex(0);
    setProd(Accessories.Down_Pipe);
  };

  const setNozzel = () => {
    setImageIndex(0);
    setProd(Accessories.Nozzel);
  };

  const setGutters = () => {
    setImageIndex(0);
    setProd(Accessories.gutters);
  };

  const setRedgeCap = () => {
    setImageIndex(0);
    setProd(Accessories.RidgeCup);
  };

  const setVallyGutters = () => {
    setImageIndex(0);
    setProd(Accessories.VallyGutters);
  };

  const setVadimbu = () => {
    setImageIndex(0);
    setProd(Accessories.Vadimbu);
  };

  const setGatterFalshing = () => {
    setImageIndex(0);
    setProd(Accessories.GutterFalshing);
  };

  const setEndCap = () => {
    setImageIndex(0);
    setProd(Accessories.EndCap);
  };

  const setImage = (img) => {
    setEachImageStatus(true);
    setEachImage(img);
  };

  useEffect(() => {}, [prod, imageIndex]);

  return (
    <div className="roofing-main-container">
      <h2>Accessories</h2>
      <div
        className="options-conatiner One-H accessories-door-option-conatiner"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div
          className={`options ${prod?.tittle === "Gutters" && "active"} `}
          onClick={setGutters}
        >
          <h3 className="option-h3">Gutters</h3>
        </div>
        <div
          className={`options ${prod?.tittle === "Vadimbu" && "active"} `}
          onClick={setVadimbu}
        >
          <h3 className="option-h3">Vadimbu</h3>
        </div>
        <div
          className={`options ${prod?.tittle === "Nozzel" && "active"} `}
          onClick={setNozzel}
        >
          <h3 className="option-h3">Nozzel</h3>
        </div>
        <div
          className={`options ${prod?.tittle === "End Cap" && "active"} `}
          onClick={setEndCap}
        >
          <h3 className="option-h3">End Cap</h3>
        </div>
        <div
          className={`options ${prod?.tittle === "Down Pipe" && "active"} `}
          onClick={setDownPipe}
        >
          <h3 className="option-h3">Down Pipe</h3>
        </div>
        <div
          className={`options ${prod?.tittle === "Ridge Cup" && "active"} `}
          onClick={setRedgeCap}
        >
          <h3 className="option-h3">Ridge Cap</h3>
        </div>
        <div
          className={`options ${
            prod?.tittle === "Valley Gutters" && "active"
          } `}
          onClick={setVallyGutters}
        >
          <h3 className="option-h3">Valley</h3>
          <h3 className="option-h3"> Gutters</h3>
        </div>
        <div
          className={`options ${
            prod?.tittle === "Gutter Flashing" && "active"
          } `}
          onClick={setGatterFalshing}
        >
          <h3 className="option-h3">Gutter</h3>
          <h3 className="option-h3">Flashing</h3>
        </div>
      </div>

      <div
        className="option-description"
        data-aos="fade-up"
        data-aos-duration="800"
      >
        <div className="op-inner-container">
          <div className="op-des-img">
            <div className="op-des-img-container">
              <img
                onClick={() => setImage(prod?.images[imageIndex])}
                src={prod?.images[imageIndex]}
                alt="service01"
                className="product-img"
              />
              <img src={logo} alt={logo} className="product-logo" />
            </div>

            <div className="options-image-select">
              {prod.images.map((img, index) => {
                return (
                  <img
                    key={index}
                    src={img}
                    onClick={() => setImageIndex(index)}
                    alt={logo}
                  />
                );
              })}
            </div>
          </div>
          <div className="op-des-cont">
            <h2>{prod?.tittle}</h2>
            <div className="op-des">
              <h3>Description</h3>
              <p>{prod?.description}</p>
            </div>
            <div className="op-des benifits">
              <h3>Benifits</h3>
              {prod?.benifits.map((benifit, index) => {
                return (
                  <p key={index}>
                    <BsArrowRightCircleFill />
                    <span>{benifit}</span>
                  </p>
                );
              })}
            </div>
            <div className="op-des benifits">
              <h3>Awailable Thikness - Size</h3>
              <div className="thik">
                {prod?.thikness.map((thick) => (
                  <div>{thick}</div>
                ))}
              </div>
            </div>
            <button className="button about-btn product-btn">
              <a
                href="https://wa.me/+94763607727?text= Is this product awailable now ?"
                target="_blank"
                rel="noreferrer"
              >
                <IoMdCall />
                <span>Contact Now</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const RollorDoorProduct = () => {
  const {
    prod,
    setProd,
    imageIndex,
    setImageIndex,
    setEachImageStatus,
    setEachImage,
  } = useCikContext();

  useEffect(() => {
    setImageIndex(0);
    setProd(Rollor_Door.Auto_Rollor_Door);
  }, [setProd, setImageIndex]);

  const setAutoRoollorDoor = () => {
    setImageIndex(0);
    setProd(Rollor_Door.Auto_Rollor_Door);
  };

  const setManualRoollorDoor = () => {
    setImageIndex(0);
    setProd(Rollor_Door.Manual_Rollor_Door);
  };

  const setImage = (img) => {
    setEachImageStatus(true);
    setEachImage(img);
  };

  useEffect(() => {}, [prod, imageIndex]);

  return (
    <div data-aos="fade-up" className="roofing-main-container">
      <h2>Roofing Sheets</h2>
      <div className="options-conatiner rollor-door-option-conatiner">
        <div
          className={`options ${
            prod?.tittle === "Auto Rollor Door" && "active"
          } `}
          onClick={setAutoRoollorDoor}
        >
          <h3 className="option-h3">Auto</h3>
          <h3>Rollor Door</h3>
        </div>
        <div
          className={`options ${
            prod?.tittle === "Manual Rollor Door" && "active"
          } `}
          onClick={setManualRoollorDoor}
        >
          <h3 className="option-h3">Manual</h3>
          <h3>Rollor Door</h3>
        </div>
      </div>

      <div className="option-description">
        <div className="op-inner-container">
          <div className="op-des-img">
            <div className="op-des-img-container">
              <img
                onClick={() => setImage(prod?.images[imageIndex])}
                src={prod?.images[imageIndex]}
                alt="service01"
                className="product-img"
              />
              <img src={logo} alt={logo} className="product-logo" />
            </div>

            <div className="options-image-select">
              {prod.images.map((img, index) => {
                return (
                  <img
                    key={index}
                    src={img}
                    onClick={() => setImageIndex(index)}
                    alt={logo}
                  />
                );
              })}
            </div>
          </div>
          <div className="op-des-cont">
            <h2>{prod?.tittle}</h2>
            <div className="op-des">
              <h3>Description</h3>
              <p>{prod?.description}</p>
            </div>
            <div className="op-des benifits">
              <h3>Benifits</h3>
              {prod?.benifits.map((benifit, index) => {
                return (
                  <p key={index}>
                    <BsArrowRightCircleFill />
                    <span>{benifit}</span>
                  </p>
                );
              })}
            </div>
            <div className="op-des benifits">
              <h3>Awailable Thikness - Size</h3>
              <div className="thik">
                <div>12.5 mm</div>
                <div>12.5 mm</div>
                <div>12.5 mm</div>
              </div>
            </div>
            <button className="button about-btn product-btn">
              <a
                href="https://wa.me/+94763607727?text= Is this product awailable now ?"
                target="_blank"
                rel="noreferrer"
              >
                <IoMdCall />
                <span>Contact Now</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SteelProduct = () => {
  const {
    prod,
    setProd,
    imageIndex,
    setImageIndex,
    setEachImageStatus,
    setEachImage,
  } = useCikContext();

  useEffect(() => {
    setProd(Steel.stirrup);
  }, [setProd]);

  const setStirrup = () => {
    setImageIndex(0);
    setProd(Steel.stirrup);
  };

  const setCpurlin = () => {
    setImageIndex(0);
    setProd(Steel.cpurlin);
  };

  const setImage = (img) => {
    setEachImageStatus(true);
    setEachImage(img);
  };

  useEffect(() => {}, [prod, imageIndex]);

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="800"
      className="roofing-main-container"
    >
      <h2>Roofing Sheets</h2>
      <div className="options-conatiner One-H  rollor-door-option-conatiner">
        <div
          className={`options ${prod?.tittle === "Stirrup" && "active"} `}
          onClick={setStirrup}
        >
          <h3 className="option-h3">Stirrup</h3>
        </div>
        <div
          className={`options ${prod?.tittle === "C-purlin" && "active"} `}
          onClick={setCpurlin}
        >
          <h3 className="option-h3">C-purlin</h3>
        </div>
      </div>

      <div className="option-description">
        <div className="op-inner-container">
          <div className="op-des-img">
            <div className="op-des-img-container">
              <img
                onClick={() => setImage(prod?.images[imageIndex])}
                src={prod?.images[imageIndex]}
                alt="service01"
                className="product-img"
              />
              <img src={logo} alt={logo} className="product-logo" />
            </div>

            <div className="options-image-select">
              {prod.images.map((img, index) => {
                return (
                  <img
                    key={index}
                    src={img}
                    onClick={() => setImageIndex(index)}
                    alt={logo}
                  />
                );
              })}
            </div>
          </div>
          <div className="op-des-cont">
            <h2>{prod?.tittle}</h2>
            <div className="op-des">
              <h3>Description</h3>
              <p>{prod?.description}</p>
            </div>
            <div className="op-des benifits">
              <h3>Benifits</h3>
              {prod?.benifits.map((benifit, index) => {
                return (
                  <p key={index}>
                    <BsArrowRightCircleFill />
                    <span>{benifit}</span>
                  </p>
                );
              })}
            </div>
            <div className="op-des benifits">
              <h3>Awailable Thikness - Size</h3>
              <div className="thik">
                <div>12.5 mm</div>
                <div>12.5 mm</div>
                <div>12.5 mm</div>
              </div>
            </div>
            <button className="button about-btn product-btn">
              <a
                href="https://wa.me/+94763607727?text= Is this product awailable now ?"
                target="_blank"
                rel="noreferrer"
              >
                <IoMdCall />
                <span>Contact Now</span>
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prooducts;
