import React, { useState } from "react";
import Banner from "../../components/Banner/Banner";
import Nav from "../../components/Nav/Nav";
import Quality from "../../components/Quality/Quality";
import About from "../../components/About/About";
import Prooducts from "../../components/Products/Prooducts";
import Contact from "../../components/Conatct/Contact";
import Footer from "../../components/Footer/Footer";
import Trust from "../../components/Trust/Trust";
import Notification from "../../components/Notification/Notification";
import Slider from "../../components/Slider/Slider";
import Shadow from "../../components/Shadow/Shadow";
import FixedIcons from "../../components/FixedIcons/FixedIcons";
import Topbar from "../../components/TopBar/Topbar";
import Eachimage from "../../components/EachImage/Eachimage";
import {motion} from 'framer-motion'
import Messanger from "../../components/Messagnger/Messanger";
import Welcome from "../../components/Welcome/Welcome";

function HomePage() {
  const [cursorX, setCursorX] = useState();
  const [cursorY, setCursorY] = useState();

  window.addEventListener("mousemove", (e) => {
    setCursorY(e.pageY);
    setCursorX(e.pageX);
  });



  return (
    <motion.div animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5}} > 
      <Topbar />
      <Notification />
      <Nav />
      <Banner />
      <Welcome />
      {/* <Quality /> */}
      <Trust />
      <About />
      <Prooducts />
      <Contact />
      <Footer />
      <Slider />
      <Shadow />
      <Eachimage />
      <FixedIcons />
      <Messanger />
      <div
        style={{ left: cursorX + "px", top: cursorY + "px" }}
        className="curosr"
      ></div>
    </motion.div>
  );
}

export default HomePage;
