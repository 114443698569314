import React from 'react'
import './Fixedicons.css'
import {BsWhatsapp  } from 'react-icons/bs'

function FixedIcons() {
  return (
    <section class="fixed-icons-wtp">
	    	<a href="https://wa.me/+94763607727"  class="whatsapp" target="_blank" rel="noreferrer"><BsWhatsapp /> </a>
	  </section>
  )
}

export default FixedIcons



    