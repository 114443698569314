import React from 'react'
import './Banner.css'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { motion } from 'framer-motion'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import sliderImage01  from '../../assets/slider/slider-03.jpg'
import sliderImage02  from '../../assets/slider/slider-02.jpg'
import sliderImage03  from '../../assets/slider/slider-01.jpg'
import {HiOutlineArrowNarrowRight , HiOutlineArrowNarrowLeft} from 'react-icons/hi'

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  nextArrow: <NextArrow  />,
  prevArrow: <PrevArrow />
};

function Banner() {
  return (
    <div>
      <Slider {...settings}>
          <SliderElement image={sliderImage02 }  first="Empowering Your" second="Drive" third="with NYK Trading" para="NYK Trading Your Pathway to Automotive Excellence Empowering Your Drive Through Premium Imports and Exports, Connecting Enthusiasts with Japan's Finest Vehicles and Spare Parts."/>
          <SliderElement image={sliderImage01}   first="We Offer Best Way To Find" second="Dream" third="vehicle" para="Discover your dream vehicle effortlessly with NYK Trading. Our streamlined platform provides the ultimate solution, uniting enthusiasts with their perfect match through expertise and dedication to quality."/>
          <SliderElement image={sliderImage03 }  first="Your Automotive" second="Journey" third="Begins Here" para="Welcome to the inception of your automotive journey. NYK Trading stands as your gateway to unparalleled vehicular experiences, uniting quality, expertise, and passion to fuel your path towards driving excellence."  />
      </Slider>
    </div>

  )
}

function NextArrow({onClick}){
  return(
      <div className='Arrow next-Arrow' onClick={onClick}>
          <HiOutlineArrowNarrowRight />
      </div>
  )
}

function PrevArrow({onClick}){
  return(
      <div className='Arrow prev-Arrow' onClick={onClick}>
          <HiOutlineArrowNarrowLeft />
      </div>
  )
}


function SliderElement({image}){
  return(
    <div className='banner-container' id='Home' style={{
      backgroundImage: `linear-gradient(-1deg, #1c1b1f 0%, rgba(28, 27, 31, 0) 100%) , url('${image}')`
    }} >
        <div className='banner-inner-container'>
            <div className='content' >
                <motion.h2 animate={{opacity:1 , y:0}} exit={{opacity:0 , y:0}} initial={{opacity:0 , y:'20px'}} transition={{duration:0.5 , delay:0.5}} >Best Roofing Services and Consulting</motion.h2>
                <motion.p animate={{opacity:1 , y:0}} exit={{opacity:0 , y:0}} initial={{opacity:0 , y:'20px'}} transition={{duration:0.5 , delay:1}} >There are many variations of passages of Lorem as Ipsumoff available, but the majority have suffered alt.</motion.p>
                <motion.button className='button' animate={{opacity:1 , y:0}} exit={{opacity:0 , y:0}} initial={{opacity:0 , y:'20px'}} transition={{duration:0.5 , delay:1.5}} ><AiOutlineArrowRight /><span>Discover More</span></motion.button>
            </div>
        </div>
    </div>
  )
}

export default Banner