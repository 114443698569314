import React from 'react'
import './Footer.css'
import {BiChevronsRight} from "react-icons/bi"
import {IoIosCall } from 'react-icons/io'
import {FaFacebookF , FaInstagram , FaTwitter} from 'react-icons/fa'

import post1 from '../../assets/Posts/f_insta_img01.jpg'
import post2 from '../../assets/Posts/f_insta_img02.jpg'
import post3 from '../../assets/Posts/f_insta_img03.jpg'
import post4 from '../../assets/Posts/f_insta_img04.jpg'
import post5 from '../../assets/Posts/f_insta_img05.jpg'
import post6 from '../../assets/Posts/f_insta_img06.jpg'

import logo from '../../assets/logowhite.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'


function Footer() {


  return (
   <> 
    <div  className='footer-conatiner'>
        <div className='inner-container inner-footer'>
            <div className='inner-container-footer'>
                <div className='col  about-us-grid' data-aos="fade-right" data-aos-duration="800" >
                    <h3>About Us</h3>
                    <p>There are many variations of passages of Lore Ipsum available, but the majori have alteration in some form, by injected humour, ondomised word which don't look</p>
                </div>
                <div className='col' data-aos="fade-right" data-aos-duration="800" >
                    <h3>Our Products</h3>

                    <div className='list'>
                        <p><AnchorLink href='#Products'><BiChevronsRight /><span>Roofing</span></AnchorLink></p>
                        <p><AnchorLink href='#Products'><BiChevronsRight /><span>Accessories</span></AnchorLink></p>
                        <p><AnchorLink href='#Products'><BiChevronsRight /><span>Rollor Gates</span></AnchorLink></p>
                        <p><AnchorLink href='#Products'><BiChevronsRight /><span>Gates</span></AnchorLink></p>
                        <p><AnchorLink href='#Products'><BiChevronsRight /><span>Steel</span></AnchorLink></p>
                    </div>
                </div>
                <div className='col' data-aos="fade-right" data-aos-duration="800" >
                    <h3>Quick Links</h3>

                    <div className='list'>
                        <p><AnchorLink href='#Home'><BiChevronsRight /><span>Home</span></AnchorLink></p>
                        <p><AnchorLink href='#About'><BiChevronsRight /><span>About</span></AnchorLink></p>
                        <p><AnchorLink href='#Contact'><BiChevronsRight /><span>Contact</span></AnchorLink></p>
                        <p><AnchorLink href='#Products'><BiChevronsRight /><span>Product</span></AnchorLink></p>
                    </div>
                </div>
                <div className='col' data-aos="fade-right" data-aos-duration="800" >
                    <h3>Instagram Posts</h3>

                    <div className='image-container' >
                        <div>
                            <img src={post1} alt='post1' />
                        </div>
                        <div>    
                            <img src={post2} alt='post2' />
                        </div>    
                        <div>    
                            <img src={post3} alt='post3' />
                        </div>    
                        <div>    
                            <img src={post4} alt='post4' />
                        </div>    
                        <div>    
                            <img src={post5} alt='post5' />
                        </div>   
                        <div>    
                            <img src={post6} alt='post6' />
                        </div>   
                    </div>
                </div>
            </div>
            <div className='company-details' >
               <div className='inner-cd' >
                    <div className='cd-col logo' data-aos="fade-up"  data-aos-duration="800" >
                        <img src={logo} alt='logo' />
                    </div>
                    <a href="https://wa.me/+94763607727"  target="_blank" rel="noreferrer" className='cd-col number-col' >

                        <div className='number-container' data-aos="fade-up" data-aos-duration="800">
                            <div className='number-inner-container' >
                                <div className='logo' >
                                    <IoIosCall />
                                </div>
                                <div className='num'>
                                    <p>Phone No</p>
                                    <p className='num-main'>+94 76 360 7727</p>
                                </div>
                            </div>
                        </div>
                        
                    </a>
                    <div className='cd-col social' >
                         <div className='follow-us'  data-aos="fade-up" data-aos-duration="800">
                                <h3>Follow Us : </h3>
                                <div className='all-links'>
                                    <a href="https://www.facebook.com/p/Central-Industries-Kandy-Private-Limited-100063541863946/"  target="_blank" rel="noreferrer" className='each-link'>
                                        <FaFacebookF />
                                    </a>
                                    <a href="https://www.facebook.com/p/Central-Industries-Kandy-Private-Limited-100063541863946/"  target="_blank" rel="noreferrer" className='each-link'>
                                        <FaInstagram />
                                    </a>
                                    <a href="https://www.facebook.com/p/Central-Industries-Kandy-Private-Limited-100063541863946/"  target="_blank" rel="noreferrer" className='each-link'>
                                        <FaTwitter />
                                    </a>
                                </div>
                         </div>
                    </div>
               </div>
            </div>
            
        </div>
    </div>
    <div className='copyright' >
          <p>© 2023 centralindustrieskandy.com All rights reserved By <a href="https://umairlafir.com/" rel="noreferrer" target='_blank'>Mohomad Lafir Umair</a> </p>
    </div>
    </>
  )
}

export default Footer