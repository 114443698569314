import React from 'react'
import welcome01 from '../../assets/images/welcome-01.jpg'
import welcome02 from '../../assets/images/welcome-02.jpg'
import './Welcome.css'
import { IoIosConstruct } from 'react-icons/io'
import { BsHouseCheck } from 'react-icons/bs'
import AnchorLink from 'react-anchor-link-smooth-scroll'

const Welcome = () => {
  return (
    <div className='Conatiner'>
        <div className='inner-container inner-container-welocome' >
            <div className='welcome-image' >
                <div className='image-01-welcome' >
                    <img src={welcome01} alt='wel-01'/>
                </div>
                <div className='image-02-welcome' >
                    <img src={welcome02} alt='wel-01'/>
                    <div className='welcome-icon' >
                        <IoIosConstruct size={60} />
                        <div>
                            <h6>certified service</h6>
                            <h2> Approved RoofRepairing</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className='welcome-desc' >
                <div className='sub-title-wel' >
                    <div className='border-left' ></div>
                        <h6>Welcome to Our roofing service</h6>
                </div>
                <h1>Committed to Give Quality Roof Providers</h1>

                <div className='wel-buttons' >
                    <div className='each-wel-btn' >
                        <BsHouseCheck size={40}  />
                        <div>
                            <h2>Solution</h2>
                            <h2 >for Residential Roofing</h2>
                        </div>
                    </div>
                    <div className='each-wel-btn' >
                        <BsHouseCheck size={40}/>
                        <div>
                            <h2>Solution</h2>
                            <h2 >for Residential Roofing</h2>
                        </div>
                    </div>
                </div>

                
                <h3>Commercial & Residential Clients across Sydney Repairs for their fast and reliable 24 hour Roofing Repair services.</h3>

                <p>Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.</p>

                <button className='button wel-btn'> <AnchorLink href='#Contact' ><span>Contact Us</span></AnchorLink> </button>
            </div>
        </div>
    </div>
  )
}

export default Welcome