import React , {createContext , useContext, useState} from 'react'
import {roofing_sheets} from '../product-db'

const CikContext = createContext()

function CentralIndustrisContaxtProvider({children}) {
  const [navStatus , setNavStatus] = useState(false);
  const [notiStatus , setNotiStatus] = useState(false)
  const [notiError , setNotiError] = useState(false)
  const [eachImageStatus , setEachImageStatus] = useState(false)
  const [eachImage , setEachImage] = useState("")
  const [notiContent , setNotiContent] = useState("")

  const [prod , setProd] = useState(roofing_sheets.roof_sheet) 
  const [imageIndex , setImageIndex] = useState(0)
  const [mainprodcat , setMainProdCat] = useState("Roofing")

  const [preloaderstatus , setPreloaderStatus] = useState(true)

  
  const values = {
    navStatus:navStatus, 
    setNavStatus:setNavStatus,
    notiStatus :notiStatus,
    setNotiStatus:setNotiStatus,
    notiContent :notiContent ,
    setNotiContent: setNotiContent,
    notiError:notiError, 
    setNotiError:setNotiError,
    prod:prod,
    setProd:setProd,
    imageIndex:imageIndex,
    setImageIndex:setImageIndex,
    mainprodcat:mainprodcat, 
    setMainProdCat:setMainProdCat,
    eachImageStatus:eachImageStatus, 
    setEachImageStatus:setEachImageStatus,
    eachImage:eachImage, 
    setEachImage:setEachImage,
    preloaderstatus:preloaderstatus, 
    setPreloaderStatus:setPreloaderStatus,
  }
  return (
    <CikContext.Provider value={values}>
        {children}
    </CikContext.Provider>
  )
}

export const useCikContext = () => (
    useContext(CikContext)
)

export default CentralIndustrisContaxtProvider