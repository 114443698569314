import React from 'react'
import {FaFacebookF , FaInstagram } from 'react-icons/fa'
import {IoIosCall } from 'react-icons/io'
import {GrMail} from 'react-icons/gr'

function Topbar() {
  return (
    <div className='tob-bar-conatiner'>
      <div className='tb-innercontainer' >
          <div className='social-mdias' > 
              <a href="https://wa.me/+94763607727" target="_blank" rel="noreferrer" className='soc' >
                  <FaFacebookF />
              </a>
              <div href="https://wa.me/+94763607727" target="_blank" rel="noreferrer" className='soc' >
                  <FaInstagram />
              </div>
          </div> 
          <div className='contact-option' >
              <a href="https://wa.me/+94763607727" target="_blank" rel="noreferrer" className='con'  >
                  <IoIosCall />
                  <span>+94 66 20 56 391</span>
              </a>
              <a href="https://wa.me/+94763607727" target="_blank" rel="noreferrer"  className='con' >
                  <GrMail />
                  <span>centralind64@gmail.com</span>
              </a>
          </div>
      </div>
    </div>
  )
}

export default Topbar