import React, { useEffect } from 'react'
import './Banner.css'

function Banner() {
    useEffect(() => {
        console.log("sasasa")
    })

    return(
        <div className='banner-container-cu'>
            <div className='banner-main-container-cu'>
                    <div className='banner-inner-conatiner-cu'>
                        <div className='Banner-cu-cont' >
                            <h2>Contact Us</h2>
                            <p>Home - <span>Contact Us</span></p>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default Banner