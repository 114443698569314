import React from 'react'
import './Shadow.css'
import { useCikContext } from '../../Context/CentralIndustrisContaxtProvider';

function Shadow() {
  const {navStatus , setNavStatus }  = useCikContext();

  const closeSlider = () => {
    setNavStatus(!navStatus)
  }

  return (
    <div onClick={closeSlider} className={`shadow ${navStatus && 'shadow-active'} `}  >

    </div>
  )
}

export default Shadow