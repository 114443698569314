import React , {useRef , useState}  from 'react'
import './Contact.css'
import {AiOutlineArrowRight} from 'react-icons/ai'
import {IoIosCall } from 'react-icons/io'
import {GrMail} from 'react-icons/gr'
import {MdLocationOn} from 'react-icons/md'
import { useCikContext } from '../../Context/CentralIndustrisContaxtProvider'

function Contact() {
  const { setNotiStatus  , setNotiContent , setNotiError} = useCikContext();    
  const form = useRef();
  const [firstname , setFirstName] = useState();
  const [secondname , setSecondName] = useState();
  const [email , setEmail] = useState();
  const [phoneNum , setPhoneNum] = useState();
  const [subject , setSubject] = useState();
  const [message , setMessage] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    if(firstname && email && message && subject && secondname && phoneNum){
        // emailjs.sendForm('service_77p0vzi', 'template_yrd3qqg', form.current, 'n-9GJj74erAXldagQ')
        // .then((result) => {
        //     console.log(result.text);
        //     if(result.text == "OK"){       
        //         setNotiContent("Email Sent Successfully")     
        //         setNotiStatus(true);
        //         setTimeout(() =>{
        //               setNotiStatus(false);
        //         },4000)
        //     }else{
        //         console.log("Not sent")
        //         setNotiContent("Email not sent")
        //     }
        // }, (error) => {
        //     console.log(error.text);
        //     setNotiContent("Something Went Wrong")
        //     setNotiStatus(true);
        //     setTimeout(() =>{
        //           setNotiStatus(false);
        //     },4000)
        // });
    }else{
        setNotiContent("Please Fill All The Feild")
        setNotiStatus(true);
        setNotiError(true)
        setTimeout(() =>{
              setNotiStatus(false);
              setNotiError(false);
        },4000)
    }
  }

  return (
   <div className='Conatiner' id='Contact'>
        <div className='inner-container inner-contactus-container' >
            <form ref={form} className='form' data-aos="fade-left" data-aos-duration="800" onSubmit={sendEmail} >
                <div className='inner-form' >
                    <h3 >Contact With Us</h3>
                    <p>Send us a message and we' ll respond as soon as possible</p>
                    <div className='form-container' >
                        <div className='fName-LName' >
                                <div className='fNAme input' >
                                    <input onChange={(e) => setFirstName(e.target.value)} type='text' placeholder='First Name' />
                                </div>
                                <div className='lNAme input' >
                                    <input onChange={(e) => setSecondName(e.target.value)} type='text' placeholder='Last Name' />
                                </div>
                        </div>
                        <div className='email-number' >
                                <div className='email input' >
                                    <input onChange={(e) => setEmail(e.target.value)} type='text' placeholder='Email' />
                                </div>
                                <div className='number input' >
                                    <input onChange={(e) => setPhoneNum(e.target.value)} type='text' placeholder='Phone Number' />
                                </div>
                        </div>
                        <div className='subject input' >
                                <input onChange={(e) => setSubject(e.target.value)} type='text'  placeholder='Subject' />
                        </div>
                        <div className='message input' >
                                <textarea onChange={(e) => setMessage(e.target.value)} placeholder='Your Mesage Here' rows="10" cols="80" />
                        </div>
                        <button className='button form-btn'><span>Send Message</span><AiOutlineArrowRight /></button>
                    </div>
                </div>
            </form>
            <div className='map-details' >
                <div className='need-any-help' data-aos="fade-down" data-aos-duration="800" >
                    <h3>Need Any Help?</h3>
                    <p>Call us or message and we' ll respond as soon as possible</p>

                    <div className='compnay-details' >
                        <div className='detail' >
                            <div className='detail-icon'>
                                <IoIosCall /> 
                            </div>
                            <p>+94 76 360 7727</p>
                            <p>+94 66 20 56 391</p>
                        </div>
                        <div className='detail' >
                            <div className='detail-icon'>
                                <GrMail /> 
                            </div>
                            <p>centralind64@gmail.com</p>
                            <p>centralind64@gmail.com</p>
                        </div>
                        <div className='detail' >
                            <div className='detail-icon'>
                                <MdLocationOn /> 
                            </div>
                            <p>64, Kandy Road, Warakamura</p>
                            <p>Matale, Sri lanka.</p>
                        </div>
                    </div>
                </div>
                <iframe data-aos="fade-up" data-aos-duration="800"  title='location'  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3956.291193617976!2d80.62054541477589!3d7.432994794637457!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zN8KwMjUnNTguOCJOIDgwwrAzNycyMS44IkU!5e0!3m2!1sen!2s!4v1688492161724!5m2!1sen!2s" width="100%"  style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>

   </div>
  )
}

export default Contact