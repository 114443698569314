import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat';

function Messanger() {
  return (
    <MessengerCustomerChat
        pageId="641070113201876"
        appId="644648964262984"
        classNamae="messanger"
    />
  )
}

export default Messanger