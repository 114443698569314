import React, { useEffect, useState } from 'react'
import './Slider.css'
import { useCikContext } from '../../Context/CentralIndustrisContaxtProvider'
import logo from '../../assets/logoblack.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {AiFillCaretRight , AiOutlineArrowRight} from 'react-icons/ai'
import {FaFacebookF , FaInstagram , FaTwitter} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import {RxDotFilled} from 'react-icons/rx'

function Slider() {
  const {navStatus , setNavStatus}  = useCikContext();
  const [mainprodtstatus , setMainproductstaus] = useState(false)
  const [mainProd , setMainProd] = useState("")


  const cancelSlider = () =>{
    setNavStatus(!navStatus)
    setMainproductstaus(false)
    setMainProd("")
  }

  const setMainproductstausFunc = () => {        
     setMainproductstaus(true)
  }

  const setSubProdFuc = (prod) => {
    if(mainProd === prod){
      setMainProd("")
    }else{
      setMainProd(prod)
    }
  }

  useEffect(() => {
    console.log(mainProd )
  },[mainProd ])

  return (
    <div className={`slider ${navStatus && 'slider-active'} `} >
        <div className='slider-inner-conatiner' >
           <div className='img-container' > 
                <img src={logo} alt='logo'/>
           </div>
            <div className='all-slider-links' >
                <p onClick={cancelSlider} className='all-sl-link' ><Link to='./'> <span>Home</span><AiFillCaretRight /></Link> </p>
                <p onClick={cancelSlider} className='all-sl-link' ><AnchorLink href='#About'> <span>About</span> <AiFillCaretRight /></AnchorLink> </p>
                <p  className='slider-product'  onClick={setMainproductstausFunc} ><span className='sub-span' ><span>Products</span> <AiFillCaretRight /> </span>
               
                        <div  className={`produt-sub-link ${mainprodtstatus && 'active'} `} >
                          
                              <h6 onClick={() => setSubProdFuc('Roofing')} > <span><AiOutlineArrowRight className={`${mainProd === 'Roofing' && 'svg-active'}`} /><span>Roofing</span></span>
                                  <div className={`roofing-slider ${mainProd === 'Roofing' && 'active'}`} >
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Normal Roof Sheet</span></AnchorLink></h6>
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Tile Roof Sheet</span></AnchorLink></h6>
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Gale Cladd Sheet</span></AnchorLink></h6>
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Curve Sheet</span></AnchorLink></h6>
                                  </div>
                              </h6>
                              
                              <h6 onClick={() => setSubProdFuc('Accessories')} > <span><AiOutlineArrowRight className={`${mainProd === 'Accessories' && 'svg-active'}`} /><span>Accessories</span> </span> 
                                  <div className={`roofing-slider ${mainProd === 'Accessories' && 'active'}`} >
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Gutters</span></AnchorLink></h6>
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Vadimbu</span></AnchorLink></h6>
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Nozzel</span></AnchorLink></h6>
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>End Cap</span></AnchorLink></h6>
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Down Pipe</span></AnchorLink></h6>
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Ridge Cap</span></AnchorLink></h6>
                                  </div>
                              </h6>
                              
                              <h6 onClick={() => setSubProdFuc('Rollor Gates')} > <span><AiOutlineArrowRight className={`${mainProd === 'Rollor Gates' && 'svg-active'}`} /><span>Rollor Gates</span> </span> 
                                  <div className={`roofing-slider ${mainProd === 'Rollor Gates' && 'active'}`} >
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Auto Rollor Door</span></AnchorLink></h6>
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Manual Rollor Door</span></AnchorLink></h6>
                                  </div>
                              </h6>
                              
                              <h6 onClick={() => setSubProdFuc('Gates')} > <span><AiOutlineArrowRight  className={`${mainProd === 'Gates' && 'svg-active'}`} /><span>Gates</span> </span> 
                                  <div className={`roofing-slider ${mainProd === 'Gates' && 'active'}`} >
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Sliding Gates</span></AnchorLink></h6>
                                  </div>
                              </h6>
                              
                              <h6 onClick={() => setSubProdFuc('Steel')} > <span><AiOutlineArrowRight className={`${mainProd === 'Steel' && 'svg-active'}`} /><span>Steel</span> </span> 
                                  <div className={`roofing-slider ${mainProd === 'Steel' && 'active'}`} >
                                      <h6><AnchorLink href='#Products'> <RxDotFilled /><span>C Purling</span></AnchorLink></h6>
                                  </div>
                              </h6>

                              
                        </div>

                    
                 </p>
                <p onClick={cancelSlider} className='all-sl-link' ><Link to='./Contact'> <span>Contact</span> <AiFillCaretRight /> </Link> </p>
                <p onClick={cancelSlider} className='all-sl-link' ><AnchorLink href='#Project'> <span>Projects</span> <AiFillCaretRight /> </AnchorLink> </p>
            </div>  
            <div className='slider-social' >
                <a href="https://www.facebook.com/p/Central-Industries-Kandy-Private-Limited-100063541863946/"  target="_blank" rel="noreferrer" ><FaFacebookF /></a>
                <a  href="https://www.facebook.com/p/Central-Industries-Kandy-Private-Limited-100063541863946/"  target="_blank" rel="noreferrer" ><FaInstagram /></a>
                <a  href="https://www.facebook.com/p/Central-Industries-Kandy-Private-Limited-100063541863946/"  target="_blank" rel="noreferrer" ><FaTwitter /></a>
            </div>

        </div>
    </div>
  )
}

export default Slider