import React from 'react'
import FixedIcons from "../../components/FixedIcons/FixedIcons";
import Shadow from "../../components/Shadow/Shadow";
import Contact from "../../components/Conatct/Contact";
import Banner from './components/Banner/Banner'
import Nav from "./components/Nav/Nav";
import Topbar from "./components/Topbar/Topbar";
import Footer from "./components/Footer/Footer";
import Slider from "./components/Slider/Slider";
import { motion } from 'framer-motion'
import {Helmet} from "react-helmet";
import Messanger from '../../components/Messagnger/Messanger';

function ContactPage() {
  return (
    <motion.div  animate={{opacity:1 }} exit={{opacity:0}} initial={{opacity:0 }} transition={{duration:0.5 }}>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Central Industries Kandy | Contact Us</title>
                <meta name="description" content="Central Industries Kandy is a leading roofing company based in Sri Lanka, specifically located in Kandy. We provide high-quality roofing solutions for residential and commercial properties across the country. Visit our website for more information." />
        </Helmet>
        <Topbar />
        <Nav />
        <Banner />
        <Contact />
        <Footer />
        <Slider />
        <FixedIcons />
        <Messanger />
        <Shadow />
    </motion.div>
  )
}

export default ContactPage