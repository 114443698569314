import React from 'react'
import { useCikContext } from '../../Context/CentralIndustrisContaxtProvider'
import logoblack from '../../assets/logoblack.png'
import {RxCross2} from 'react-icons/rx'
import './Eachimage.css'

function Eachimage() {
  const {eachImageStatus , setEachImageStatus , eachImage} = useCikContext()  

  return (
<div className={`${eachImageStatus ? 'shadow-each-image shadow-active-each-image' : 'shadow-each-image'} `} >
    <div className='image-each-container'>
        <div className='image-each-inner-container' >
                <img className='each-logo-img' src={logoblack} alt={logoblack} />
                <img className='each-image-shadow' src={eachImage} alt={eachImage} /> 
        </div>
    </div>
    <RxCross2 onClick={() => setEachImageStatus(false)}  />
</div>
  )
}

export default Eachimage