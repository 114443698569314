import React from 'react'
import './Trust.css'
import {BsFillPlayFill} from 'react-icons/bs'
import {BiCheck} from 'react-icons/bi'

function Trust() {
  return (
    <div className='trust-container conatiner'>
          <div className='trust-company-shape-1 '></div>
          <div className='trust-company-shape-2'></div>
          <div className='trust-company-shape-3'></div>
          <div className='inner-container inner-conatiner-trust' >
                <div className='video-tittle' data-aos="fade-right" data-aos-duration="800">
                    <div class="fixed-icons">
                        <a href="https://wa.me/+60139277180" class="whatsapp-trust" target="_blank" rel="noreferrer"><BsFillPlayFill /> </a>
                    </div>
                    <h1>Hundreds of customers trust our company</h1>
                </div>
                <div className='trusting' >
                        <p data-aos="fade-up" data-aos-duration="800">
                            <BiCheck/>
                            <span>We’ve expert & certified staff</span>
                        </p>
                        <p data-aos="fade-up" data-aos-duration="800">
                            <BiCheck />
                            <span>We’ve expert & certified staff</span>
                        </p>
                        <p data-aos="fade-up" data-aos-duration="800"> 
                            <BiCheck />
                            <span>We’ve expert & certified staff</span>
                        </p>
                </div>

          </div>
    </div>
  )
}

export default Trust