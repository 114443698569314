import React, { useState  , useEffect} from 'react'
import logoblack from '../../assets/logoblack.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import {IoMdCall} from 'react-icons/io'
import {AiFillCaretRight , AiOutlineArrowRight} from 'react-icons/ai'
import { useCikContext } from '../../Context/CentralIndustrisContaxtProvider'
import {Link} from 'react-router-dom'
import {RxDotFilled} from 'react-icons/rx'
import './Nav.css'

function Nav() {
  const [navShow , setNavShow] = useState(false);
  const {navStatus , setNavStatus} = useCikContext()  


  const transtionNavBar = () => {
    if(window.scrollY > 300) {
        setNavShow(true)
    }else{
        setNavShow(false)
    }
  }  


  useEffect(() => {
      window.addEventListener("scroll" , transtionNavBar)

      return () => window.removeEventListener("scroll" , transtionNavBar)
  }, [])

  return (
    <nav className={`${navShow && 'nav-white'} `}>
        <div className='nav-container'>
            <div className='nav-inner-container'>
                <div className='image-container'>
                        <img  src={logoblack} alt='logo'/>
                </div>
                <div className='navlink-parent-container' >
                    <div className='navlink-container'>
                        <ul>
                            <li><Link to={'../'}>Home</Link> </li>
                            <li><Link to={'./about'}>About</Link></li>
                            <li className='product-li' ><AiFillCaretRight className='drop-icon' /><span>Products</span>
                                <div className='product-dropdown' >
                                <div className='prduct-dropdown-inner'>

                                        <h6 className='roofing-dropdown' ><AnchorLink href='#Products'> <AiOutlineArrowRight className='product-dropdown-svg' /><span className='product-dropdown-head' >Roofing</span></AnchorLink>
                                            <div className='sub-product-dropdown sub-product-dropdown-roof' >
                                            <div className='sub-prduct-dropdown-inner'>
                                                <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Normal Roof Sheet</span></AnchorLink></h6>
                                                <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Tile Roof Sheet</span></AnchorLink></h6>
                                                <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Gale Cladd Sheet</span></AnchorLink></h6>
                                                <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Curve Sheet</span></AnchorLink></h6>
                                            </div>
                                            </div>
                                        </h6>

                                        <h6 className='Acessories-dropdown' ><AnchorLink href='#Products'> <AiOutlineArrowRight className='product-dropdown-svg' /><span className='product-dropdown-head' >Accessories</span></AnchorLink>
                                            <div className='sub-product-dropdown sub-product-dropdown-Accesories' >
                                                <div className='sub-prduct-dropdown-inner'>
                                                    <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Gutters</span></AnchorLink></h6>
                                                    <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Vadimbu</span></AnchorLink></h6>
                                                    <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Nozzel</span></AnchorLink></h6>
                                                    <h6><AnchorLink href='#Products'> <RxDotFilled /><span>End Cap</span></AnchorLink></h6>
                                                    <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Down Pipe</span></AnchorLink></h6>
                                                    <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Ridge Cap</span></AnchorLink></h6>
                                                </div>
                                            </div>
                                        </h6>

                                        <h6 className='RollorGates-dropdown' ><AnchorLink href='#Products'> <AiOutlineArrowRight className='product-dropdown-svg' /><span className='product-dropdown-head' >Rollor Gates</span></AnchorLink>
                                            <div className='sub-product-dropdown sub-product-dropdow-rg' >
                                            <div className='sub-prduct-dropdown-inner'>
                                                <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Auto Rollor Door</span></AnchorLink></h6>
                                                <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Manual Rollor Door</span></AnchorLink></h6>
                                            </div>
                                            </div>
                                        </h6>

                                        <h6 className='Gates-dropdown' ><AnchorLink href='#Products'> <AiOutlineArrowRight className='product-dropdown-svg' /><span className='product-dropdown-head' >Gates</span></AnchorLink>
                                            <div className='sub-product-dropdown sub-product-dropdow-gates' >
                                            <div className='sub-prduct-dropdown-inner'>
                                                <h6><AnchorLink href='#Products'> <RxDotFilled /><span>Sliding Gates</span></AnchorLink></h6>
                                            </div>
                                            </div>
                                        </h6>

                                        <h6 className='Steel-dropdown' ><AnchorLink href='#Products'> <AiOutlineArrowRight className='product-dropdown-svg' /><span className='product-dropdown-head' >Steel</span></AnchorLink>
                                            <div className='sub-product-dropdown sub-product-dropdow-steel' >
                                            <div className='sub-prduct-dropdown-inner'>
                                                <h6><AnchorLink href='#Products'> <RxDotFilled /><span>C-purling</span></AnchorLink></h6>
                                            </div>
                                            </div>
                                        </h6>

                                </div>
                                </div>
                            </li>
                            <li><Link to='../Contact'>Contact</Link> </li>
                            <li><AnchorLink href='#Project'>Projects</AnchorLink> </li>
                        </ul>
                    </div>
                    <div className='button-container'>
                        <button className='button'> <AnchorLink href='#Contact' ><IoMdCall /><span>Get a Quote</span></AnchorLink> </button>
                    </div>
                </div>
                
                <div className='Nav-menu'>
                    <div className={`${navStatus  ? 'burger toggle' : 'burger'} `} onClick={() => setNavStatus(!navStatus)}>
                        <div className="line1"></div>
                        <div className="line2"></div>
                        <div className="line3"></div>
                    </div>
               </div>
            </div>
        </div>
    </nav>
  )
}

export default Nav